import CategoryCard from "@/Components/Course/CategoryCard";
import { getImage } from "@/constants";
import { useAppSelector } from "@/redux/hooks";
import { RootState } from "@/redux/store";
import { usePage } from "@inertiajs/react";
import { map, range } from "lodash";
import { ChevronLeft, ChevronRight } from "lucide-react";
import { useRef } from "react";
import Slider from "react-slick";

export default function () {
    const { isRTL } = useAppSelector((state: RootState) => state.locale);
    const {
        ziggy: { query },
    }: any = usePage().props;
    const refSlider = useRef<Slider | null>(null);

    var settings = {
        rtl: isRTL,
        dots: false,
        // dotsClass: "slick-dots block lg:hidden",
        className: "center",
        centerMode: true,
        infinite: true,
        centerPadding: "60px",
        adaptiveHeight: true,
        swipeToSlide: true,
        speed: 500,
        slidesToShow: 6,
        slidesToScroll: 6,
        initialSlide: 6,
        // focusOnSelect: true,
        arrows: false,
        responsive: [
            {
                breakpoint: 2500,
                settings: {
                    slidesToShow: 6,
                    slidesToScroll: 6,
                    initialSlide: 6,
                },
            },
            {
                breakpoint: 1450,
                settings: {
                    slidesToShow: 6,
                    initialSlide: 6,
                    slidesToScroll: 6,
                },
            },
            {
                breakpoint: 1250,
                settings: {
                    slidesToShow: 4,
                    initialSlide: 4,
                    slidesToScroll: 4,
                },
            },
            {
                breakpoint: 1000,
                settings: {
                    slidesToShow: 2,
                    initialSlide: 2,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 500,
                settings: {
                    slidesToShow: 2,
                    initialSlide: 2,
                    slidesToScroll: 2,
                },
            },
        ],
    };

    const RenderArrows = () => {
        return (
            <div className="relative z-50 hidden lg:flex w-[110%] md:w-[108%] xl:w-[105%] h-0  justify-between items-center m-auto -bottom-10 rtl:-right-10 ltr:-left-10">
                <ChevronLeft
                    onClick={() => refSlider?.current?.slickPrev()}
                    className="relative rtl:rotate-180 ltr:right-2 rtl:left-2 h-8 w-8 text-gray-700 border border-gray-200 bg-gray-100 hover:bg-gray-200 rounded-xl p-2"
                />
                <ChevronRight
                    onClick={() => refSlider?.current?.slickNext()}
                    className="relative rtl:rotate-180 ltr:left-2 rtl:right-2 h-8 w-8 text-gray-700 border border-gray-200 bg-gray-100 hover:bg-gray-200 rounded-xl p-2"
                />
            </div>
        );
    };

    return (
        <div className="px-8">
            <RenderArrows />
            <Slider {...settings} ref={refSlider}>
                {map(range(1, 15), (s: any, i) => (
                    <img
                        loading="lazy"
                        key={i}
                        src={getImage(`groups/${i}.jpg`)}
                        className="w-20 h-20 md:w-40 md:h-40 object-contain"
                    />
                ))}
            </Slider>
        </div>
    );
}
